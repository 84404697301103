<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item>名片交换记录记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="exhibitor" label="参展商名称"></el-table-column>
        <el-table-column prop="user" label="用户"></el-table-column>
        <el-table-column prop="thumbnail" label="交换名片时间">
          <template slot-scope="scope">
            {{datafromatfull(scope.row.creat_time)}}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      dataList: [],
      exhibitionTitle: ''
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/exhibition-change-record', { params: { m_id: this.$route.params.m_id } })
      if (res.status === 200) {
        this.dataList = res.data.list
        this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
      } else {
        this.$message.error(res.msg)
      }
    },
    datafromatfull (time) {
      time = parseInt(parseInt(time) / 1000)
      return this.$moment.unix(time).format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>
.menu-area{
  padding: 0 0 20px 0;
  text-align: right;
}
.banner-img{
  max-width: 50px;
  max-height: 50px;
}
</style>
